export const CHANNEL_TYPES = {
  DRIBBLE: "DRIBBLE",
  GITHUB: "GITHUB",
  INSTAGRAM: "INSTAGRAM",
  LINKEDIN: "LINKEDIN",
  TELEGRAM: "TELEGRAM",
  TWITTER: "TWITTER",
  WEBSITE: "WEBSITE",
  YOUTUBE: "YOUTUBE",
  BUYMECOFFEE: "BUYMECOFFEE",
  ANIMEREPORT: "ANIMEREPORT",
  TIKTOK: "TIKTOK",
  OTHER: "OTHER",
};
