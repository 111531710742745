import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Linktree, { CHANNEL_TYPES } from "./modules/Linkees/index";
import AdsComponent from "./components/adsense";
import AdBanner from "./components/adsterra";
function App() {
  const items = [
    {
      title: "Anime Report",
      subtitle: "Sign up to receive information about my upcoming anime app!",
      type: CHANNEL_TYPES.ANIMEREPORT,
      link: "https://signup.animereport.com", //your personal website or portfolio  link
    },
    {
      title: "Tiktok",
      subtitle: "@AnimeReportApp | Will be trying to post more",
      type: CHANNEL_TYPES.TIKTOK,
      link: "https://tiktok.com/@animereportapp", //instagram profile link
    },
    {
      title: "Buy Me A Coffee",
      subtitle: "No support necessary, but if you want here it is",
      type: CHANNEL_TYPES.BUYMECOFFEE,
      link: "https://buymeacoffee.com/animereport", //Github Profile link
    },

    {
      title: "X | Twitter",
      subtitle: "@AnimeReportApp | Will be trying to post more",
      type: CHANNEL_TYPES.TWITTER,
      link: "https://twitter.com/animereportapp", //instagram profile link
    },
    {
      title: "Instagram",
      subtitle: "@AnimeReportApp | Will be trying to post more",
      type: CHANNEL_TYPES.INSTAGRAM,
      link: "https://instagram.com/animereportapp", //instagram profile link
    },
  ];

  return (
    <div className="App">
      <Linktree
        cardItems={items}
        name={"Anime Report"}
        //headerAvatar="https://fastly.picsum.photos/id/851/200/300.jpg?hmac=AD_d7PsSrqI2zi-ubHY_-urUxCN77Gnev3k5o0P6nlE"
      />
      <AdsComponent dataAdSlot="23144725044" />
      <p>Ad spot to generate revenue for the app: </p>
      <AdBanner />
    </div>
  );
}

export default App;
